import { Box, Link, Skeleton, Stack, Typography } from '@mui/material';
import { SearchThemeItem, useInstallTheme, useSearchThemes } from 'api/themes';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { Grid, GRID_PER_PAGE_OPTIONS } from 'component/new_design/base/Grid/Grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import ThemesIcon from 'component/new_design/icons/Layers.svg?react';
import ShareIcon from 'component/new_design/icons/Share.svg?react';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import he from 'he';
import { Select } from 'component/new_design/base/forms/Select';
import { RoleGuard } from 'component/base/RoleGuard';

export const ThemesGrid = () => {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();

  const gridState = usePaginatedListState({
    perPageOptions: GRID_PER_PAGE_OPTIONS,
    filterKeys: ['query'],
  });
  const themes = useSearchThemes(gridState, siteId!);
  const installTheme = useInstallTheme(siteId!);

  const [installingTheme, setInstallingTheme] = useState<string | null>(null);

  const handleInstallClick = (theme: SearchThemeItem) => async () => {
    setInstallingTheme(theme.name || null);
    try {
      await installTheme.mutateAsync({
        themes: theme.name,
        activate: true,
      });
    } finally {
      setInstallingTheme(null);
    }
  };

  const categories = [
    { label: t('blog'), value: 'blog' },
    { label: t('business'), value: 'business' },
    { label: t('fashion'), value: 'fashion' },
    { label: t('food'), value: 'food' },
    { label: t('real_estate'), value: 'real+estate' },
    { label: t('sports'), value: 'sports' },
    { label: t('technology'), value: 'technology' },
  ];

  return (
    <Grid
      title={t('discover_themes')}
      data={themes.data?.data.result || []}
      totalRowCount={themes.data?.data.metadata?.total || 0}
      isLoading={themes.isLoading}
      state={gridState}
      searchPlaceholder={t('search_themes')}
      enableSearch
      enablePagination
      emptyState={<EmptyState icon={<ThemesIcon />} title={t('no_themes_available')} />}
      rowIdKey="name"
      renderItem={theme => (
        <Stack>
          <Box
            component="img"
            alt={theme.name}
            src={theme.screenshot_url}
            sx={{ aspectRatio: '4/3', width: '100%', borderRadius: 'var(--border-radius-m)' }}
          />
          <Stack p={4} gap={4}>
            <Stack gap={4} direction="row">
              <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {he.decode(theme.title!)}
              </Typography>
              <Link
                sx={{
                  ml: 'auto',
                }}
                href={theme.preview_url}
                target="_blank"
              >
                <IconWrapper size="s" color="blues.500">
                  <ShareIcon />
                </IconWrapper>
              </Link>
            </Stack>
            <RoleGuard roles={['billing_admin']} type="block">
              <LoadingButton
                sx={{ mt: 'auto', alignSelf: 'flex-start' }}
                variant="contained"
                color="secondary"
                loading={installingTheme === theme.name}
                onClick={handleInstallClick(theme)}
              >
                {t('install')}
              </LoadingButton>
            </RoleGuard>
          </Stack>
        </Stack>
      )}
      renderItemLoader={() => <Skeleton height={224} />}
      columnGap={4}
      rowGap={1}
    >
      <Select
        options={categories}
        value={gridState.params.filters.query}
        onChange={value => gridState.setFilter('query', value)}
        enableEmptyOption
        emptyOptionLabel={t('all')}
      />
    </Grid>
  );
};
