import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { linkHelper } from 'linkHelper';
import { Suspense, useContext, useState, lazy } from 'react';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isInIframe } from 'utils/iframe';
import { SiteDetail, useSiteDetails } from 'api/site';
import { SiteSelectorMenu } from '../partial/SiteSelectorMenu';
import { isSiteAccess, useGetToken } from 'utils/token';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { LoadingButton } from '@mui/lab';
import CurrentMode from '../partial/CurrentMode';
import { SiteDetailsOverview } from '../partial/SiteDetailsOverview';
import { FileManagerTable } from '../partial/tables/file-manager/FileManagerTable';
import { ActivityLogTable } from '../partial/tables/logs/ActivityLogTable';
import { AccessLogTable } from '../partial/tables/logs/AccessLogTable';
import Logs from './SiteDetails/Logs';
import Reporting from './SiteDetails/Reporting';
import { ReportingOverview } from '../partial/Reporting/ReportingOverview';
import { ReportingDelivery } from '../partial/Reporting/ReportingDelivery';
import { BackButton } from '../partial/BackButton';
// icons
import PencilEdit from '../icons/PencilEdit.svg?react';
import ClipboardTextDuotone from '../icons/ClipboardTextDuotone';
import FileSettingsDuotone from '../icons/FileSettingsDuotone';
import FolderSearchDuotone from '../icons/FolderSearchDuotone';
import LayersDuotone from '../icons/LayersDuotone';
import LockCloseDuotone from '../icons/LockCloseDuotone';
import PluginDuotone from '../icons/PluginDuotone';
import ReceiptDuotone from '../icons/ReceiptDuotone';
import RocketShipDuotone from '../icons/RocketShipDuotone';
import ServerDuotone from '../icons/ServerDuotone';
import Wordpress from '../icons/Wp.svg?react';
// mobile menu icons
import FolderSearch from '../icons/FolderSearch.svg?react';
import Layers from '../icons/Layers.svg?react';
import PluginAddon from '../icons/PluginAddon.svg?react';
import FileSettings from '../icons/FileSettings.svg?react';
import Server from '../icons/Server.svg?react';
import ClipboardText from '../icons/ClipboardText.svg?react';
import Receipt from '../icons/Receipt.svg?react';
import LockClose from '../icons/LockClose.svg?react';
import RocketShip from '../icons/RocketShipSolid.svg?react';
import { ChangeLabelDialog } from '../partial/ChangeLabelDialog';
import Navigation, { NavigationItem } from '../partial/Navigation';
import Plugins from './SiteDetails/Plugins';
import { PluginsTable } from '../partial/tables/plugins/PluginsTable';
import { PluginsGrid } from '../partial/grids/plugins/PluginsGrid';
import { ClearCacheDialog } from '../partial/SiteDetails/Overview/ClearCacheDialog';
import Themes from './SiteDetails/Themes';
import { ThemesGrid } from '../partial/grids/themes/ThemesGrid';
import { ThemesTable } from '../partial/tables/themes/ThemesTable';
import { RoleGuard } from 'component/base/RoleGuard';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';
import { SiteStatus } from '../partial/SiteStatus';

const Backups = lazy(() => import('./SiteDetails/Backups'));
const Security = lazy(() => import('./SiteDetails/Security'));
const Advanced = lazy(() => import('./SiteDetails/Advanced'));

function TopButtons({ siteId }: { readonly siteId: string }) {
  // TODO - get this from API
  const isSiteLocked = false;
  // TODO - get this from API
  const isLoading = false;
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();

  return (
    <RoleGuard roles={['billing_admin']} type="block">
      <Stack direction="row" gap={2}>
        <ClearCacheDialog />
        <SiteLockedPopover isSiteLocked={isSiteLocked}>
          <LoadingButton
            size="large"
            onClick={() => {
              openExternalLink({
                iframeUrl: `/manage/${siteId}/wordpress?token=${window.sessionStorage.getItem(
                  'user_session'
                )}`,
                url: `/manage/${siteId}/wordpress`,
                relative: true,
              });
            }}
            variant="contained"
            color="tertiary"
            startIcon={<Wordpress />}
            fullWidth
            disabled={isSiteLocked}
            loading={isLoading}
          >
            {t('wp_admin')}
          </LoadingButton>
        </SiteLockedPopover>
      </Stack>
    </RoleGuard>
  );
}

function FirstRow({
  isSmallBreakpoint,
  siteId,
}: {
  readonly isSmallBreakpoint: boolean;
  readonly siteId: string;
}) {
  if (isSmallBreakpoint) {
    return (
      <Stack justifyContent="space-between" direction="row">
        <BackButton to={linkHelper.newDesign.sites.getLink()} />
        <TopButtons siteId={siteId} />
      </Stack>
    );
  }
  return <BackButton to={linkHelper.newDesign.sites.getLink()} />;
}

function SecondRow({
  siteId,
  siteDetails,
}: {
  readonly siteId: string;
  readonly siteDetails: SiteDetail | undefined;
}) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const { t } = useTranslation();
  const [showSiteLabelDialog, setShowSiteLabelDialog] = useState(false);
  const token = useGetToken();
  const isBillingAdmin = useCheckUserRole('billing_admin');

  const domainName = (
    <Stack
      direction="row"
      alignItems="baseline"
      sx={{ flexWrap: 'wrap', gap: 2, alignItems: 'center' }}
    >
      {sdk || isInIframe() ? (
        <Box>
          <Typography variant="h1" data-testid="domainLabel">
            {siteDetails?.domain ?? ''}
          </Typography>
        </Box>
      ) : (
        <SiteSelectorMenu />
      )}
      {!isSiteAccess(token) && !sdk ? (
        <Button
          onClick={() => setShowSiteLabelDialog(true)}
          variant="contained"
          color="tertiary"
          size="small"
          sx={{
            color: 'greys.500',
          }}
          disabled={isBillingAdmin}
        >
          {siteDetails?.label ?? t('add_label')}
          {siteDetails?.label ? <PencilEdit /> : null}
        </Button>
      ) : null}
    </Stack>
  );

  if (isSmallBreakpoint) {
    return domainName;
  }

  return (
    <Stack justifyContent="space-between" direction="row">
      {siteDetails && showSiteLabelDialog ? (
        <ChangeLabelDialog site={siteDetails} onClose={() => setShowSiteLabelDialog(false)} />
      ) : null}
      {domainName}
      <TopButtons siteId={siteId} />
    </Stack>
  );
}

export default function SiteDetails() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { data, isLoading } = useSiteDetails({ id: siteId });
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const siteDetails = data?.data.result;
  const { t } = useTranslation();
  const showFileManager = !useCheckUserRole('billing_admin');

  const navigationMenuItems = [
    {
      solidIcon: <FolderSearch />,
      duotoneIcon: FolderSearchDuotone,
      title: t('overview'),
      to: linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId }),
    },
    {
      solidIcon: <PluginAddon />,
      duotoneIcon: PluginDuotone,
      title: t('plugin_plural'),
      to: linkHelper.newDesign.sites.siteDetails.plugins.getLink({ siteId }),
    },
    {
      solidIcon: <Layers />,
      duotoneIcon: LayersDuotone,
      title: t('theme_plural'),
      to: linkHelper.newDesign.sites.siteDetails.themes.getLink({ siteId }),
    },
    showFileManager
      ? {
          solidIcon: <FileSettings />,
          duotoneIcon: FileSettingsDuotone,
          title: t('file_manager'),
          to: linkHelper.newDesign.sites.siteDetails.fileManager.getLink({
            siteId,
          }),
        }
      : null,
    {
      solidIcon: <Server />,
      duotoneIcon: ServerDuotone,
      title: t('backup_plural'),
      to: linkHelper.newDesign.sites.siteDetails.backups.getLink({ siteId }),
    },
    {
      solidIcon: <ClipboardText />,
      duotoneIcon: ClipboardTextDuotone,
      title: t('log_plural'),
      to: linkHelper.newDesign.sites.siteDetails.logs.getLink({ siteId }),
    },
    {
      solidIcon: <Receipt />,
      duotoneIcon: ReceiptDuotone,
      title: t('reporting'),
      to: linkHelper.newDesign.sites.siteDetails.reporting.getLink({ siteId }),
    },
    {
      solidIcon: <LockClose />,
      duotoneIcon: LockCloseDuotone,
      title: t('security'),
      to: linkHelper.newDesign.sites.siteDetails.security.getLink({ siteId }),
    },
    {
      solidIcon: <RocketShip />,
      duotoneIcon: RocketShipDuotone,
      title: t('advanced'),
      to: linkHelper.newDesign.sites.siteDetails.advanced.getLink({ siteId }),
    },
  ].filter(Boolean) satisfies (NavigationItem | null)[] as NavigationItem[];

  return (
    <Page>
      <Stack gap={6}>
        <FirstRow siteId={siteId} isSmallBreakpoint={isSmallBreakpoint} />
        <SecondRow siteId={siteId} siteDetails={siteDetails} />
        <SiteStatus siteDetails={siteDetails} />
        <CurrentMode isLoading={isLoading} siteDetails={siteDetails} />
        <Navigation items={navigationMenuItems} />
        <Routes>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.overview.path}
            element={
              <Suspense>
                <SiteDetailsOverview siteDetails={siteDetails} />
              </Suspense>
            }
          />
          <Route path={linkHelper.newDesign.sites.siteDetails.plugins.path} element={<Plugins />}>
            <Route index element={<PluginsTable />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.plugins.discover.path}
              element={<PluginsGrid />}
            />
          </Route>
          <Route path={linkHelper.newDesign.sites.siteDetails.themes.path} element={<Themes />}>
            <Route index element={<ThemesTable />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.themes.discover.path}
              element={<ThemesGrid />}
            />
          </Route>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.fileManager.path}
            element={<FileManagerTable siteId={siteId} />}
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.logs.path}
            element={<Logs siteId={siteId} />}
          >
            <Route index element={<ActivityLogTable siteId={siteId} />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.logs.accessLog.path}
              element={<AccessLogTable siteId={siteId} />}
            />
          </Route>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.backups.path}
            element={
              <Suspense>
                <Backups siteId={siteId} />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.reporting.path}
            element={<Reporting siteId={siteId} />}
          >
            <Route index element={<ReportingOverview />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.reporting.delivery.path}
              element={<ReportingDelivery />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to={linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId })}
                replace
              />
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.security.path}
            element={
              <Suspense>
                <Security siteId={siteId} />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.advanced.path}
            element={
              <Suspense>
                <Advanced siteId={siteId} />
              </Suspense>
            }
          />
        </Routes>
      </Stack>
    </Page>
  );
}
